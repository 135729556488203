<template>
  <div class="index">
    <!-- 心理科普 -->
    <div class="banner">
      <img v-if="!$store.state.isPhone" :src="$store.state.bannerUrl + $store.state.bannerImgs.xlkp" alt="">
      <img v-else src="../../assets/images/banner/心理科普banner备份 3.jpg" alt=""/>
      <!-- <img :src="$store.state.bannerUrl + $store.state.bannerImgs.xlkp" alt=""> -->
    </div>
    <div :class="isPc === 'true' ? 'main': 'phoneStyle main'">
      <div class="headTop">
        <ul>
          <li :class="nowPath === item.num ? 'activeLi' : ''" v-for="item in dataList" :key="item.id"
            @click="toPath(item.num)">
            <span class="span3">{{ item.name }}</span>
          </li>
          <!-- <li><span class="span3">个性</span></li>
          <li><span class="span3">关系</span></li>
          <li><span class="span3">个人成长</span></li> -->
        </ul>
      </div>
      <div class="searchContent">
        <i class="el-icon-search"></i>
        <el-input placeholder="| 请输入" v-model="searchContent" clearable>
        </el-input>
        <div class="searchBtn" @click="search">
          <span class="span1">搜索一下</span>
        </div>
      </div>
      <!-- <router-view></router-view> -->
      <div class="healthContent">
      <!-- <div class="item" v-for="item in dataList" :key="item.id">
        <div class="title"></div>
      </div> -->
      <div class="item" v-for="item in data" :key="item.id" @click="toDetail(item.id)">
        <div class="name">
          <div class="box1"></div>
          <div class="box2"></div>
          <p class="p2">{{ item.title }}
            <img src="../../assets/images/index/gaugeLogo.png" alt="">
          </p>
        </div>
        <div class="text">
          <div class="memo">
            <span class="span2 memoText">
              {{ item.summary }}</span>
            <!-- <div class="btn">
                  <span class="span2">
                    开始测试
                  </span>
                </div> -->
          </div>


        </div>
      </div>
    </div>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange"
            :current-page.sync="paging.pageNumber" :page-size="paging.pageSize" layout="prev, pager, next, jumper" :total="paging.total">
          </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { newsListAPI, getArticle } from '@/api/news'
export default {
  data() {
    return {
      isPc: true,
      dataList: [
        { id: 1, name: '心理健康', path: '/health',num: 1 },
        { id: 2, name: '个性', path: '/personality', num: 2 },
        { id: 3, name: '关系', path: '/relationship', num: 3 },
        { id: 4, name: '个人成长', path: '/grow', num: 4 }
      ],
      nowPath: 1,
      data: [],
      searchContent: null,
      paging: {
        pageNumber: 1,
        pageSize: 8,
        total: 0,
      },
      num:0
    }
  },
  methods: {
    // 切换分类
    toPath(val) {
      this.nowPath = val;
      
      this.getList();
      // this.$router.push(val)
    },
    // 获取文章列表
    async getList() {
      // console.log()
      // let time = Date.now();
      let info = {
        categoryId: Number(this.$store.state.healthTypeId),
        pageNumber: this.paging.pageNumber,
        pageSize: this.paging.pageSize
        // orderBy:'created desc'
      }
      
      if(this.nowPath === 2) {
        info.categoryId = Number(this.$store.state.personalityTypeId)
      } else if(this.nowPath === 3) {
        info.categoryId = Number(this.$store.state.relationshipTypeId)
      }else if(this.nowPath === 4) {
        info.categoryId = Number(this.$store.state.growTypeId)
      } else {
        info.categoryId = Number(this.$store.state.healthTypeId)
      }
      const res = await newsListAPI(info);
      console.log(res);
      if(res.state === 'ok' && res.page){
        // console.log(res.page)
        this.data = res.page.list
        this.paging.total = res.page.totalRow
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.paging.pageNumber = val;
      // this.getList();
      if(this.searchContent === null) {
        this.getList();
        // console.log('无搜索值')
      } else {
        // console.log("有搜索值")
        this.search(val);
      }
      // console.log(`当前页: ${val}`);
    },
    toDetail(val) {
      this.$router.push({
        path: '/universal/detail',
        query: {
          id:val,
          active: this.nowPath
        }
      })
    },
    // 点击搜索
    async search(val) {
        // pageNumber: this.paging.pageNumber,
      // this.paging.pageNumber = val;
      if(this.searchContent === null || this.searchContent === '') {
        this.getList();
        // console.log('无搜索值')
      } else {

      
      if(this.num === 0) {
        this.paging.pageNumber = 1;
      }
      let info = {
        categoryId: Number(this.$store.state.healthTypeId),
        pageNumber: this.paging.pageNumber,
        pageSize: this.paging.pageSize,
        keyword: this.searchContent
        // orderBy:'created desc'
      }
      const res = await getArticle(info);
      console.log(res);
      if(res.state === 'ok' && res.page){
        // console.log(res.page)
        this.num ++;
        this.data = res.page.list
        this.paging.total = res.page.totalRow
      }
      // this.getList();
    }
  }
  },

  created() {
    this.isPc = sessionStorage.getItem('isPc')
    this.getList();
    if(this.$route.query.active) {
      this.nowPath = Number(this.$route.query.active)
    }
  },
  
}
</script>

<style lang="scss" scoped>
.index {
  .banner {
    img {
      width: 100%;
      height: 300px;
    }
  }

  .main {
    display: block;
    padding-top: 30px;

    .headTop {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-end;

        li {
          width: 143px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-weight: 600;
          color: var(--headFont-color);
          background: #FFFFFF;
          border: 1px solid #DDDDDD;
        }

        .activeLi {
          width: 143px;
          height: 54px;
          line-height: 54px;
          background: var(--custom-color);
          border: 0px;
        }
      }
    }

    .searchContent {
      position: relative;
      margin-top: 30px;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;

      .el-icon-search {
        position: absolute;
        z-index: 3;
        font-size: 20px;
        color: #CCCCCC;
        line-height: 42px;
        left: 10px;
      }

      .el-input {
        // box-sizing: border-box;
        width: 435px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;

        ::v-deep .el-input__inner {
          // box-sizing: border-box;
          border: 0px !important;
          color: #DDDDDD !important;
          padding-left: 34px !important;
        }
      }

      .searchBtn {
        // display: inline-block;
        margin-left: 14px;
        width: 124px;
        height: 42px;
        background: var(--hover-Color);
        text-align: center;
        cursor: pointer;
        span {

          font-weight: 600;
          color: #FFFFFF;
          line-height: 42px;
        }
      }
    }
    
  }
}
@media screen and (max-width: 650px){
  .index .main .searchContent .searchBtn{
    position: absolute;
    right: 0;
  }
}
@media screen and (max-width: 600px) {
  .index {
    .healthContent, .personalityContent, .relationshipContent, .growContent  {
    .item{
      width: 527px;
    }
  }
  }
  
}
</style>
<style lang="scss">
.healthContent, .personalityContent, .relationshipContent, .growContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


    .item {
      width: 48%;
      box-sizing: border-box;
      margin-bottom: 20px;

      .name {
        // height: 39px;
        padding: 9px 0;
        width: 100%;
        // line-height: 39px;
        background: var(--listStyle-color);
        position: relative;
        border-right: 1px solid var(--listStyle-color);
        box-sizing: border-box;
        border-left: 0px;

        p {
          padding-left: 20px;
          font-weight: 600;
          color: var(--headFont-color);
          box-sizing: border-box;

          img {
            position: absolute;
            right: 20px;
            // top:0;
            height: 25px;
          }

          // line-height: 39px;
        }

        .box1 {
          position: absolute;
          left: -12px;
          top: 0px;
          width: 0;
          height: 0;
          border-color: transparent transparent var(--listStyle-color) transparent;
          border-style: solid;
          border-width: 0 0 38px 12px;
        }

        .box2 {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 0;
          height: 0;
          border-color: transparent transparent white transparent;
          border-style: solid;
          border-width: 0 0 38px 12px;
        }

      }

      .text {
        // margin-top: 20px;
        border: 1px solid var(--listStyle-color);
        border-top: none;

        .memo {
          padding: 13px 0px;
          padding-left: 20px;
          padding-right: 10px;
        }

        .memoText {
          margin-bottom: 13px;
          color: var(--memoFont-color);
          // line-height: 22px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        // span {
        //   // display: inline-block;
        //   // padding: 13px 0px;

        // }


      }

      &:hover {
        cursor: pointer;
        .name {
          background: var(--hover-Color);
          border-right: 1px solid var(--hover-Color);
          .box1 {
            border-color: transparent transparent var(--hover-Color) transparent;
          }
        }
        .text{
          border: 1px solid var(--hover-Color);
          border-top: 0px;
        }


      }
    }
  }
</style>